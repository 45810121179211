/* eslint-disable jsx-a11y/alt-text */
import React, { useCallback, useEffect, useState } from 'react'
import Image from 'next/image'
import useEmblaCarousel from 'embla-carousel-react'
import type { Hit } from 'react-instantsearch-core'

import { useDispatch, useSelect } from 'store/index'
import { setShouldLoadGtm } from 'store/search'

import { useImageLoader } from 'hooks/useImageLoader'

import style from './ResultCarousel.module.scss'

import getListingCloudinaryId from 'utils/strings/getListingCloudinaryId'
import getCloudinaryPlaceholder from 'utils/strings/getCloudinaryPlaceholder'

import PrevIcon from 'assets/icons/icon-chevronLeft.svg'
import NextIcon from 'assets/icons/icon-chevronRight.svg'

import type { AlgoliaListing } from 'types/externalData'

export const PrevButton = ({ enabled, onClick }) => (
  <button
    className={`${style.embla__button} ${
      !enabled && style.embla__button__disabled
    } ${style.embla__button__prev} `}
    onClick={onClick}
  >
    <PrevIcon className={style.embla__button__svg} />
  </button>
)

export const NextButton = ({ enabled, onClick }) => (
  <button
    className={`${style.embla__button} ${
      !enabled && style.embla__button__disabled
    } ${style.embla__button__next}`}
    onClick={onClick}
  >
    <NextIcon className={style.embla__button__svg} />
  </button>
)

type ResultCarouselProps = {
  hit: Hit<AlgoliaListing>
  mapDisplay: boolean
  resultIndex?: number
}

const ResultCarousel: React.FC<ResultCarouselProps> = ({ hit, mapDisplay }) => {
  const [mainViewportRef, embla] = useEmblaCarousel({
    align: 'start',
    speed: 20,
    skipSnaps: false,
  })
  const [paginationViewportRef, emblaPagination] = useEmblaCarousel({
    containScroll: 'keepSnaps',
    draggable: false,
    speed: 20,
  })
  const [prevBtnEnabled, setPrevBtnEnabled] = useState(false)
  const [nextBtnEnabled, setNextBtnEnabled] = useState(false)
  const [loadAllImages, setLoadAllImages] = useState(false)
  const [activeIndex, setActiveIndex] = useState(0)

  const activeView = useSelect((state) => state.uiState.activeView)
  const appDispatch = useDispatch()
  const imageLoader = useImageLoader()

  const scrollPrev = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault()
      event.stopPropagation()
      embla && embla.scrollPrev()
    },
    [embla],
  )
  const scrollNext = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault()
      event.stopPropagation()
      embla && embla.scrollNext()
    },
    [embla],
  )

  useEffect(() => {
    if (!embla) return
    const onSelect = () => {
      if (!embla || !emblaPagination) return
      setActiveIndex(embla.selectedScrollSnap())
      emblaPagination.scrollTo(embla.selectedScrollSnap())
      setPrevBtnEnabled(embla.canScrollPrev())
      setNextBtnEnabled(embla.canScrollNext())
    }
    onSelect()
    embla.on('select', onSelect)
  }, [embla, emblaPagination, mapDisplay])

  useEffect(() => {
    if (!embla) return
    embla.on('scroll', () => {
      setLoadAllImages(true)
    })
  }, [embla])

  return (
    <div className={`${style.embla} ${style.dynamicHeight}`}>
      <div
        className={`${style.embla__viewport} ${style.dynamicHeight}`}
        ref={mainViewportRef}
      >
        <div className={`${style.embla__container} ${style.dynamicHeight}`}>
          {hit.images?.map((img, i) => (
            <div
              className={`${style.embla__slide} ${style.dynamicHeight}`}
              key={img.URL}
            >
              {img.URL ? (
                mapDisplay ? (
                  <Image
                    alt=""
                    className={style.embla__slide__img}
                    height={147}
                    loader={imageLoader}
                    loading={
                      (activeView === 'map' && i === 0) || loadAllImages
                        ? 'eager'
                        : undefined
                    }
                    onLoad={() => appDispatch(setShouldLoadGtm())}
                    src={getListingCloudinaryId(img.URL)}
                    width={220}
                  />
                ) : (
                  <div
                    className={`image is-16by9-desktop is-3by2-mobile ${style.dynamicHeight}`}
                  >
                    <Image
                      alt=""
                      loader={imageLoader}
                      src={getListingCloudinaryId(img.URL)}
                      {...(i === 0 || loadAllImages
                        ? {
                            loading: 'eager',
                            placeholder: 'blur',
                            blurDataURL: getCloudinaryPlaceholder(
                              getListingCloudinaryId(img.URL),
                            ),
                          }
                        : i < 3
                        ? {
                            loading: 'lazy',
                            placeholder: 'blur',
                            blurDataURL: getCloudinaryPlaceholder(
                              getListingCloudinaryId(img.URL),
                            ),
                          }
                        : {
                            loading: 'lazy',
                          })}
                      fill
                      onLoad={() => appDispatch(setShouldLoadGtm())}
                      sizes="(max-width: 600px) calc(100vw - 3rem), 320px"
                      style={{
                        objectFit: 'cover',
                      }}
                    />
                  </div>
                )
              ) : (
                <span>Image not available</span>
              )}
            </div>
          ))}
        </div>
      </div>
      <PrevButton enabled={prevBtnEnabled} onClick={scrollPrev} />
      <NextButton enabled={nextBtnEnabled} onClick={scrollNext} />
      <div className={`${style.embla} ${style.embla__dots}`}>
        <div className={style.embla__viewport} ref={paginationViewportRef}>
          <div className={`${style.embla__container}`}>
            {hit.images?.map((_, index) => (
              <div className={`${style.embla__slide} `} key={index}>
                <div
                  className={`${style.embla__dot} ${
                    index === activeIndex ? style.is_selected : ''
                  }`}
                ></div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ResultCarousel
